@import url(https://fonts.googleapis.com/css?family=Rubik:300,300i,400,400i,500,500i,700,700i,900,900i&display=swap);



@font-face{
	font-family:ubuntu;
	src:url(../../custom-fonts/ubuntu-font-family/Ubuntu-L.ttf);
}
@font-face{
	font-family:ubuntuR;
	src:url(../../custom-fonts/ubuntu-font-family/Ubuntu-R.ttf);
}
@font-face{
	font-family:ubuntuMR;
	src:url(../../custom-fonts/ubuntu-font-family/UbuntuMono-R.ttf);
}
body{
	margin-top:50px;
	font-family: ubuntu;
}


/* body {
  font-family: Rubik;
} */
.display-1 {
  font-family: 'ubuntu', sans-serif;
  font-size: 3.5rem;
  font-display: swap;
}
.display-1 > .mbr-iconfont {
  font-size: 6.8rem;
}
.display-2 {
  font-family: 'ubuntu', sans-serif;
  font-size: 3rem;
  font-display: swap;
}
.display-2 > .mbr-iconfont {
  font-size: 4.8rem;
}
.display-4 {
  font-family: 'ubuntu', sans-serif;
  font-size: 1rem;
  font-display: swap;
}
.display-4 > .mbr-iconfont {
  font-size: 1.6rem;
}
.display-5 {
  font-family: 'ubuntu', sans-serif;
  font-size: 1.5rem;
  font-display: swap;
}
.display-5 > .mbr-iconfont {
  font-size: 2.4rem;
}
.display-7 {
  font-family: 'ubuntu', sans-serif;
  font-size: 1rem;
  font-display: swap;
}
.display-7 > .mbr-iconfont {
  font-size: 1.6rem;
}
/* ---- Fluid typography for mobile devices ---- */
/* 1.4 - font scale ratio ( bootstrap == 1.42857 ) */
/* 100vw - current viewport width */
/* (48 - 20)  48 == 48rem == 768px, 20 == 20rem == 320px(minimal supported viewport) */
/* 0.65 - min scale variable, may vary */
@media (max-width: 768px) {
  .display-1 {
    font-size: 3.4rem;
    font-size: calc( 2.1374999999999997rem + (4.25 - 2.1374999999999997) * ((100vw - 20rem) / (48 - 20)));
    line-height: calc( 1.4 * (2.1374999999999997rem + (4.25 - 2.1374999999999997) * ((100vw - 20rem) / (48 - 20))));
  }
  .display-2 {
    font-size: 2.4rem;
    font-size: calc( 1.7rem + (3 - 1.7) * ((100vw - 20rem) / (48 - 20)));
    line-height: calc( 1.4 * (1.7rem + (3 - 1.7) * ((100vw - 20rem) / (48 - 20))));
  }
  .display-4 {
    font-size: 0.8rem;
    font-size: calc( 1rem + (1 - 1) * ((100vw - 20rem) / (48 - 20)));
    line-height: calc( 1.4 * (1rem + (1 - 1) * ((100vw - 20rem) / (48 - 20))));
  }
  .display-5 {
    font-size: 1.2rem;
    font-size: calc( 1.175rem + (1.5 - 1.175) * ((100vw - 20rem) / (48 - 20)));
    line-height: calc( 1.4 * (1.175rem + (1.5 - 1.175) * ((100vw - 20rem) / (48 - 20))));
  }
}
/* Buttons */
.btn {
  padding: 1rem 3rem;
  border-radius: 3px;
}
.btn-sm {
  padding: 0.6rem 1.5rem;
  border-radius: 3px;
}
.btn-md {
  padding: 1rem 3rem;
  border-radius: 3px;
}
.btn-lg {
  padding: 1.2rem 3.2rem;
  border-radius: 3px;
}
.bg-primary {
  background-color: #149dcc !important;
}
.bg-success {
  background-color: #f7ed4a !important;
}
.bg-info {
  background-color: #82786e !important;
}
.bg-warning {
  background-color: #879a9f !important;
}
.bg-danger {
  background-color: #b1a374 !important;
}
.btn-primary,
.btn-primary:active {
  background-color: #149dcc !important;
  border-color: #149dcc !important;
  color: #ffffff !important;
}
.btn-primary:hover,
.btn-primary:focus,
.btn-primary.focus,
.btn-primary.active {
  color: #ffffff !important;
  background-color: #0d6786 !important;
  border-color: #0d6786 !important;
}
.btn-primary.disabled,
.btn-primary:disabled {
  color: #ffffff !important;
  background-color: #0d6786 !important;
  border-color: #0d6786 !important;
}
.btn-secondary,
.btn-secondary:active {
  background-color: #ff3366 !important;
  border-color: #ff3366 !important;
  color: #ffffff !important;
}
.btn-secondary:hover,
.btn-secondary:focus,
.btn-secondary.focus,
.btn-secondary.active {
  color: #ffffff !important;
  background-color: #e50039 !important;
  border-color: #e50039 !important;
}
.btn-secondary.disabled,
.btn-secondary:disabled {
  color: #ffffff !important;
  background-color: #e50039 !important;
  border-color: #e50039 !important;
}
.btn-info,
.btn-info:active {
  background-color: #82786e !important;
  border-color: #82786e !important;
  color: #ffffff !important;
}
.btn-info:hover,
.btn-info:focus,
.btn-info.focus,
.btn-info.active {
  color: #ffffff !important;
  background-color: #59524b !important;
  border-color: #59524b !important;
}
.btn-info.disabled,
.btn-info:disabled {
  color: #ffffff !important;
  background-color: #59524b !important;
  border-color: #59524b !important;
}
.btn-success,
.btn-success:active {
  background-color: #f7ed4a !important;
  border-color: #f7ed4a !important;
  color: #3f3c03 !important;
}
.btn-success:hover,
.btn-success:focus,
.btn-success.focus,
.btn-success.active {
  color: #3f3c03 !important;
  background-color: #eadd0a !important;
  border-color: #eadd0a !important;
}
.btn-success.disabled,
.btn-success:disabled {
  color: #3f3c03 !important;
  background-color: #eadd0a !important;
  border-color: #eadd0a !important;
}
.btn-warning,
.btn-warning:active {
  background-color: #879a9f !important;
  border-color: #879a9f !important;
  color: #ffffff !important;
}
.btn-warning:hover,
.btn-warning:focus,
.btn-warning.focus,
.btn-warning.active {
  color: #ffffff !important;
  background-color: #617479 !important;
  border-color: #617479 !important;
}
.btn-warning.disabled,
.btn-warning:disabled {
  color: #ffffff !important;
  background-color: #617479 !important;
  border-color: #617479 !important;
}
.btn-danger,
.btn-danger:active {
  background-color: #b1a374 !important;
  border-color: #b1a374 !important;
  color: #ffffff !important;
}
.btn-danger:hover,
.btn-danger:focus,
.btn-danger.focus,
.btn-danger.active {
  color: #ffffff !important;
  background-color: #8b7d4e !important;
  border-color: #8b7d4e !important;
}
.btn-danger.disabled,
.btn-danger:disabled {
  color: #ffffff !important;
  background-color: #8b7d4e !important;
  border-color: #8b7d4e !important;
}
.btn-white {
  color: #333333 !important;
}
.btn-white,
.btn-white:active {
  background-color: #ffffff !important;
  border-color: #ffffff !important;
  color: #808080 !important;
}
.btn-white:hover,
.btn-white:focus,
.btn-white.focus,
.btn-white.active {
  color: #808080 !important;
  background-color: #d9d9d9 !important;
  border-color: #d9d9d9 !important;
}
.btn-white.disabled,
.btn-white:disabled {
  color: #808080 !important;
  background-color: #d9d9d9 !important;
  border-color: #d9d9d9 !important;
}
.btn-black,
.btn-black:active {
  background-color: #333333 !important;
  border-color: #333333 !important;
  color: #ffffff !important;
}
.btn-black:hover,
.btn-black:focus,
.btn-black.focus,
.btn-black.active {
  color: #ffffff !important;
  background-color: #0d0d0d !important;
  border-color: #0d0d0d !important;
}
.btn-black.disabled,
.btn-black:disabled {
  color: #ffffff !important;
  background-color: #0d0d0d !important;
  border-color: #0d0d0d !important;
}
.btn-primary-outline,
.btn-primary-outline:active {
  background: none;
  border-color: #0b566f;
  color: #0b566f;
}
.btn-primary-outline:hover,
.btn-primary-outline:focus,
.btn-primary-outline.focus,
.btn-primary-outline.active {
  color: #ffffff;
  background-color: #149dcc;
  border-color: #149dcc;
}
.btn-primary-outline.disabled,
.btn-primary-outline:disabled {
  color: #ffffff !important;
  background-color: #149dcc !important;
  border-color: #149dcc !important;
}
.btn-secondary-outline,
.btn-secondary-outline:active {
  background: none;
  border-color: #cc0033;
  color: #cc0033;
}
.btn-secondary-outline:hover,
.btn-secondary-outline:focus,
.btn-secondary-outline.focus,
.btn-secondary-outline.active {
  color: #ffffff;
  background-color: #ff3366;
  border-color: #ff3366;
}
.btn-secondary-outline.disabled,
.btn-secondary-outline:disabled {
  color: #ffffff !important;
  background-color: #ff3366 !important;
  border-color: #ff3366 !important;
}
.btn-info-outline,
.btn-info-outline:active {
  background: none;
  border-color: #4b453f;
  color: #4b453f;
}
.btn-info-outline:hover,
.btn-info-outline:focus,
.btn-info-outline.focus,
.btn-info-outline.active {
  color: #ffffff;
  background-color: #82786e;
  border-color: #82786e;
}
.btn-info-outline.disabled,
.btn-info-outline:disabled {
  color: #ffffff !important;
  background-color: #82786e !important;
  border-color: #82786e !important;
}
.btn-success-outline,
.btn-success-outline:active {
  background: none;
  border-color: #d2c609;
  color: #d2c609;
}
.btn-success-outline:hover,
.btn-success-outline:focus,
.btn-success-outline.focus,
.btn-success-outline.active {
  color: #3f3c03;
  background-color: #f7ed4a;
  border-color: #f7ed4a;
}
.btn-success-outline.disabled,
.btn-success-outline:disabled {
  color: #3f3c03 !important;
  background-color: #f7ed4a !important;
  border-color: #f7ed4a !important;
}
.btn-warning-outline,
.btn-warning-outline:active {
  background: none;
  border-color: #55666b;
  color: #55666b;
}
.btn-warning-outline:hover,
.btn-warning-outline:focus,
.btn-warning-outline.focus,
.btn-warning-outline.active {
  color: #ffffff;
  background-color: #879a9f;
  border-color: #879a9f;
}
.btn-warning-outline.disabled,
.btn-warning-outline:disabled {
  color: #ffffff !important;
  background-color: #879a9f !important;
  border-color: #879a9f !important;
}
.btn-danger-outline,
.btn-danger-outline:active {
  background: none;
  border-color: #7a6e45;
  color: #7a6e45;
}
.btn-danger-outline:hover,
.btn-danger-outline:focus,
.btn-danger-outline.focus,
.btn-danger-outline.active {
  color: #ffffff;
  background-color: #b1a374;
  border-color: #b1a374;
}
.btn-danger-outline.disabled,
.btn-danger-outline:disabled {
  color: #ffffff !important;
  background-color: #b1a374 !important;
  border-color: #b1a374 !important;
}
.btn-black-outline,
.btn-black-outline:active {
  background: none;
  border-color: #000000;
  color: #000000;
}
.btn-black-outline:hover,
.btn-black-outline:focus,
.btn-black-outline.focus,
.btn-black-outline.active {
  color: #ffffff;
  background-color: #333333;
  border-color: #333333;
}
.btn-black-outline.disabled,
.btn-black-outline:disabled {
  color: #ffffff !important;
  background-color: #333333 !important;
  border-color: #333333 !important;
}
.btn-white-outline,
.btn-white-outline:active,
.btn-white-outline.active {
  background: none;
  border-color: #ffffff;
  color: #ffffff;
}
.btn-white-outline:hover,
.btn-white-outline:focus,
.btn-white-outline.focus {
  color: #333333;
  background-color: #ffffff;
  border-color: #ffffff;
}
.text-primary {
  color: #149dcc !important;
}
.text-secondary {
  color: #ff3366 !important;
}
.text-success {
  color: #f7ed4a !important;
}
.text-info {
  color: #82786e !important;
}
.text-warning {
  color: #879a9f !important;
}
.text-danger {
  color: #b1a374 !important;
}
.text-white {
  color: #ffffff !important;
}
.text-black {
  color: #000000 !important;
}
a.text-primary:hover,
a.text-primary:focus {
  color: #0b566f !important;
}
a.text-secondary:hover,
a.text-secondary:focus {
  color: #cc0033 !important;
}
a.text-success:hover,
a.text-success:focus {
  color: #d2c609 !important;
}
a.text-info:hover,
a.text-info:focus {
  color: #4b453f !important;
}
a.text-warning:hover,
a.text-warning:focus {
  color: #55666b !important;
}
a.text-danger:hover,
a.text-danger:focus {
  color: #7a6e45 !important;
}
a.text-white:hover,
a.text-white:focus {
  color: #b3b3b3 !important;
}
a.text-black:hover,
a.text-black:focus {
  color: #4d4d4d !important;
}
.alert-success {
  background-color: #70c770;
}
.alert-info {
  background-color: #82786e;
}
.alert-warning {
  background-color: #879a9f;
}
.alert-danger {
  background-color: #b1a374;
}
.mbr-section-btn a.btn:not(.btn-form) {
  border-radius: 100px;
}
.mbr-section-btn a.btn:not(.btn-form):hover,
.mbr-section-btn a.btn:not(.btn-form):focus {
  box-shadow: none !important;
}
.mbr-section-btn a.btn:not(.btn-form):hover,
.mbr-section-btn a.btn:not(.btn-form):focus {
  box-shadow: 0 10px 40px 0 rgba(0, 0, 0, 0.2) !important;
  -webkit-box-shadow: 0 10px 40px 0 rgba(0, 0, 0, 0.2) !important;
}
.mbr-gallery-filter li a {
  border-radius: 100px !important;
}
.mbr-gallery-filter li.active .btn {
  background-color: #149dcc;
  border-color: #149dcc;
  color: #ffffff;
}
.mbr-gallery-filter li.active .btn:focus {
  box-shadow: none;
}
.nav-tabs .nav-link {
  border-radius: 100px !important;
}
a{
  color:white;
}

a:hover {
  color: #FDD964;
}
.mbr-plan-header.bg-primary .mbr-plan-subtitle,
.mbr-plan-header.bg-primary .mbr-plan-price-desc {
  color: #b4e6f8;
}
.mbr-plan-header.bg-success .mbr-plan-subtitle,
.mbr-plan-header.bg-success .mbr-plan-price-desc {
  color: #ffffff;
}
.mbr-plan-header.bg-info .mbr-plan-subtitle,
.mbr-plan-header.bg-info .mbr-plan-price-desc {
  color: #beb8b2;
}
.mbr-plan-header.bg-warning .mbr-plan-subtitle,
.mbr-plan-header.bg-warning .mbr-plan-price-desc {
  color: #ced6d8;
}
.mbr-plan-header.bg-danger .mbr-plan-subtitle,
.mbr-plan-header.bg-danger .mbr-plan-price-desc {
  color: #dfd9c6;
}
/* Scroll to top button*/
.scrollToTop_wraper {
  display: none;
}
.form-control {
  font-family: 'Rubik', sans-serif;
  font-size: 1rem;
  font-display: swap;
}
.form-control > .mbr-iconfont {
  font-size: 1.6rem;
}
blockquote {
  border-color: #149dcc;
}
/* Forms */
.mbr-form .btn {
  margin: .4rem 0;
}
.mbr-form .input-group-btn a.btn {
  border-radius: 100px !important;
}
.mbr-form .input-group-btn a.btn:hover {
  box-shadow: 0 10px 40px 0 rgba(0, 0, 0, 0.2);
}
.mbr-form .input-group-btn button[type="submit"] {
  border-radius: 100px !important;
  padding: 1rem 3rem;
}
.mbr-form .input-group-btn button[type="submit"]:hover {
  box-shadow: 0 10px 40px 0 rgba(0, 0, 0, 0.2);
}
.form2 .form-control {
  border-top-left-radius: 100px;
  border-bottom-left-radius: 100px;
}
.form2 .input-group-btn a.btn {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}
.form2 .input-group-btn button[type="submit"] {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}
.form3 input[type="email"] {
  border-radius: 100px !important;
}
@media (max-width: 349px) {
  .form2 input[type="email"] {
    border-radius: 100px !important;
  }
  .form2 .input-group-btn a.btn {
    border-radius: 100px !important;
  }
  .form2 .input-group-btn button[type="submit"] {
    border-radius: 100px !important;
  }
}
@media (max-width: 767px) {
  .btn {
    font-size: .75rem !important;
  }
  .btn .mbr-iconfont {
    font-size: 1rem !important;
  }
}
/* Footer */
.mbr-footer-content li::before,
.mbr-footer .mbr-contacts li::before {
  background: #149dcc;
}
.mbr-footer-content li a:hover,
.mbr-footer .mbr-contacts li a:hover {
  color: #149dcc;
}
.footer3 input[type="email"],
.footer4 input[type="email"] {
  border-radius: 100px !important;
}
.footer3 .input-group-btn a.btn,
.footer4 .input-group-btn a.btn {
  border-radius: 100px !important;
}
.footer3 .input-group-btn button[type="submit"],
.footer4 .input-group-btn button[type="submit"] {
  border-radius: 100px !important;
}
/* Headers*/
.header13 .form-inline input[type="email"],
.header14 .form-inline input[type="email"] {
  border-radius: 100px;
}
.header13 .form-inline input[type="text"],
.header14 .form-inline input[type="text"] {
  border-radius: 100px;
}
.header13 .form-inline input[type="tel"],
.header14 .form-inline input[type="tel"] {
  border-radius: 100px;
}
.header13 .form-inline a.btn,
.header14 .form-inline a.btn {
  border-radius: 100px;
}
.header13 .form-inline button,
.header14 .form-inline button {
  border-radius: 100px !important;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .card-wrapper {
    flex: auto !important;
  }
}
.jq-selectbox li:hover,
.jq-selectbox li.selected {
  background-color: #149dcc;
  color: #ffffff;
}
.jq-selectbox .jq-selectbox__trigger-arrow,
.jq-number__spin.minus:after,
.jq-number__spin.plus:after {
  transition: 0.4s;
  border-top-color: currentColor;
  border-bottom-color: currentColor;
}
.jq-selectbox:hover .jq-selectbox__trigger-arrow,
.jq-number__spin.minus:hover:after,
.jq-number__spin.plus:hover:after {
  border-top-color: #149dcc;
  border-bottom-color: #149dcc;
}
.xdsoft_datetimepicker .xdsoft_calendar td.xdsoft_default,
.xdsoft_datetimepicker .xdsoft_calendar td.xdsoft_current,
.xdsoft_datetimepicker .xdsoft_timepicker .xdsoft_time_box > div > div.xdsoft_current {
  color: #ffffff !important;
  background-color: #149dcc !important;
  box-shadow: none !important;
}
.xdsoft_datetimepicker .xdsoft_calendar td:hover,
.xdsoft_datetimepicker .xdsoft_timepicker .xdsoft_time_box > div > div:hover {
  color: #ffffff !important;
  background: #ff3366 !important;
  box-shadow: none !important;
}
.lazy-bg {
  background-image: none !important;
}
.lazy-placeholder:not(section),
.lazy-none {
  display: block;
  position: relative;
  padding-bottom: 56.25%;
}
iframe.lazy-placeholder,
.lazy-placeholder:after {
  content: '';
  position: absolute;
  width: 100px;
  height: 100px;
  background: transparent no-repeat center;
  background-size: contain;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='32' height='32' viewBox='0 0 64 64' xmlns='http://www.w3.org/2000/svg' stroke='%23149dcc' %3e%3cg fill='none' fill-rule='evenodd'%3e%3cg transform='translate(16 16)' stroke-width='2'%3e%3ccircle stroke-opacity='.5' cx='16' cy='16' r='16'/%3e%3cpath d='M32 16c0-9.94-8.06-16-16-16'%3e%3canimateTransform attributeName='transform' type='rotate' from='0 16 16' to='360 16 16' dur='1s' repeatCount='indefinite'/%3e%3c/path%3e%3c/g%3e%3c/g%3e%3c/svg%3e");
}
section.lazy-placeholder:after {
  opacity: 0.3;
}
.cid-swfF6Fel6g {
  padding-top: 90px;
  padding-bottom: 90px;
  background-color: #ffffff;
}
.cid-swfF6Fel6g .mbr-section-subtitle {
  color: #767676;
  font-weight: 300;
}
.cid-swfF6Fel6g .number-wrap {
  color: #4f4943;
  border-color: rgba(255, 255, 255, 0.15);
  position: relative;
  display: inline-block;
  text-align: center;
  padding: 5px 10px 13px 10px;
  margin: 0;
  min-width: 118px;
  border-radius: 2px;
  max-width: 100%;
}
.cid-swfF6Fel6g .number {
  font-style: normal;
  font-weight: 700;
  font-size: 55px;
  text-transform: none;
  letter-spacing: -2px;
  word-spacing: 0;
  line-height: 1.3;
  color: #232323;
}
.cid-swfF6Fel6g .period {
  display: block;
  padding-top: 2px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
.cid-swfF6Fel6g .dot {
  position: absolute;
  top: -10px;
  right: -1em;
  width: 1em;
  display: block;
  height: 83%;
  overflow: hidden;
  font-style: normal;
  font-weight: 700;
  font-size: 55px;
  line-height: 89.65px;
  text-transform: none;
  letter-spacing: 0;
  word-spacing: 0;
  color: #232323;
}
.cid-swfF6Fel6g .countdown-cont {
  max-width: 700px;
  margin: 0 auto;
}
@media (max-width: 768px) {
  .cid-swfF6Fel6g .dot {
    display: none;
  }
}
@media (max-width: 543px) {
  .cid-swfF6Fel6g .number-wrap {
    margin-bottom: 15px;
  }
}
@media (max-width: 550px) {
  .cid-swfF6Fel6g .col-xs-3 {
    padding-left: 0;
    padding-right: 0;
    max-width: 25%;
    flex: 0 0 25%;
  }
  .cid-swfF6Fel6g .number-wrap {
    min-width: auto;
  }
}
@media (max-width: 440px) {
  .cid-swfF6Fel6g .number,
  .cid-swfF6Fel6g .dot {
    font-size: 40px;
  }
}
@media (max-width: 380px) {
  .cid-swfF6Fel6g .period {
    font-size: 0.8rem;
  }
}
.cid-swfEENVNJh .navbar {
  padding: .5rem 0;
  background: #333333;
  transition: none;
  min-height: 60px;
  /* height: 50px; */
}
.cid-swfEENVNJh .navbar-dropdown.bg-color.transparent.opened {
  background: #333333;
}
.cid-swfEENVNJh a {
  font-style: normal;
}
.cid-swfEENVNJh .nav-item span {
  padding-right: 0.4em;
  line-height: 0.5em;
  vertical-align: text-bottom;
  position: relative;
  text-decoration: none;
}
.cid-swfEENVNJh .nav-item a {
  display: -webkit-flex;
  align-items: center;
  justify-content: center;
  padding: 0.7rem 0 !important;
  margin: 0rem .65rem !important;
  -webkit-align-items: center;
  -webkit-justify-content: center;
}
.cid-swfEENVNJh .nav-item:focus,
.cid-swfEENVNJh .nav-link:focus {
  outline: none;
}
.cid-swfEENVNJh .btn {
  padding: 0.4rem 1.5rem;
  display: -webkit-inline-flex;
  align-items: center;
  -webkit-align-items: center;
}
.cid-swfEENVNJh .btn .mbr-iconfont {
  font-size: 1.6rem;
}
.cid-swfEENVNJh .menu-logo {
  margin-right: auto;
}
.cid-swfEENVNJh .menu-logo .navbar-brand {
  display: flex;
  margin-left: 5rem;
  padding: 0;
  transition: padding .2s;
  min-height: 3.8rem;
  -webkit-align-items: center;
  align-items: center;
}
.cid-swfEENVNJh .menu-logo .navbar-brand .navbar-caption-wrap {
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  word-break: break-word;
  min-width: 7rem;
  margin: .3rem 0;
}
.cid-swfEENVNJh .menu-logo .navbar-brand .navbar-caption-wrap .navbar-caption {
  line-height: 1.2rem !important;
  padding-right: 2rem;
}
.cid-swfEENVNJh .menu-logo .navbar-brand .navbar-logo {
  font-size: 4rem;
  transition: font-size 0.25s;
}
.cid-swfEENVNJh .menu-logo .navbar-brand .navbar-logo img {
  display: flex;
}
.cid-swfEENVNJh .menu-logo .navbar-brand .navbar-logo .mbr-iconfont {
  transition: font-size 0.25s;
}
.cid-swfEENVNJh .menu-logo .navbar-brand .navbar-logo a {
  display: inline-flex;
}
.cid-swfEENVNJh .navbar-toggleable-sm .navbar-collapse {
  justify-content: flex-end;
  -webkit-justify-content: flex-end;
  padding-right: 5rem;
  width: auto;
}
.cid-swfEENVNJh .navbar-toggleable-sm .navbar-collapse .navbar-nav {
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  padding-left: 0;
}
.cid-swfEENVNJh .navbar-toggleable-sm .navbar-collapse .navbar-nav .nav-item {
  -webkit-align-self: center;
  align-self: center;
}
.cid-swfEENVNJh .navbar-toggleable-sm .navbar-collapse .navbar-buttons {
  padding-left: 0;
  padding-bottom: 0;
}
.cid-swfEENVNJh .dropdown .dropdown-menu {
  background: #333333;
  visibility: hidden;
  display: block;
  position: absolute;
  min-width: 5rem;
  padding-top: 1.4rem;
  padding-bottom: 1.4rem;
  text-align: left;
}
.cid-swfEENVNJh .dropdown .dropdown-menu .dropdown-item {
  width: auto;
  padding: 0.235em 1.5385em 0.235em 1.5385em !important;
}
.cid-swfEENVNJh .dropdown .dropdown-menu .dropdown-item::after {
  right: 0.5rem;
}
.cid-swfEENVNJh .dropdown .dropdown-menu .dropdown-submenu {
  margin: 0;
}
.cid-swfEENVNJh .dropdown.open > .dropdown-menu {
  visibility: visible;
}
.cid-swfEENVNJh .navbar-toggleable-sm.opened:after {
  position: absolute;
  width: 100vw;
  height: 100vh;
  content: '';
  background-color: rgba(0, 0, 0, 0.1);
  left: 0;
  bottom: 0;
  transform: translateY(100%);
  -webkit-transform: translateY(100%);
  z-index: 1000;
}
/* .cid-swfEENVNJh .navbar.navbar-short {
  min-height: 60px;
  transition: all .2s;
}
.cid-swfEENVNJh .navbar.navbar-short .navbar-toggler-right {
  top: 20px;
}
.cid-swfEENVNJh .navbar.navbar-short .navbar-logo a {
  font-size: 2.5rem !important;
  line-height: 2.5rem;
  transition: font-size 0.25s;
}
.cid-swfEENVNJh .navbar.navbar-short .navbar-logo a .mbr-iconfont {
  font-size: 2.5rem !important;
}
.cid-swfEENVNJh .navbar.navbar-short .navbar-logo a img {
  height: 2rem !important;
}
.cid-swfEENVNJh .navbar.navbar-short .navbar-brand {
  min-height: 3rem;
} */
.cid-swfEENVNJh button.navbar-toggler {
  width: 31px;
  height: 18px;
  cursor: pointer;
  transition: all .2s;
  top: 1.5rem;
  right: 1rem;
}
.cid-swfEENVNJh button.navbar-toggler:focus {
  outline: none;
}
.cid-swfEENVNJh button.navbar-toggler .hamburger span {
  position: absolute;
  right: 0;
  width: 30px;
  height: 2px;
  border-right: 5px;
  background-color: #ffffff;
}
.cid-swfEENVNJh button.navbar-toggler .hamburger span:nth-child(1) {
  top: 0;
  transition: all .2s;
}
.cid-swfEENVNJh button.navbar-toggler .hamburger span:nth-child(2) {
  top: 8px;
  transition: all .15s;
}
.cid-swfEENVNJh button.navbar-toggler .hamburger span:nth-child(3) {
  top: 8px;
  transition: all .15s;
}
.cid-swfEENVNJh button.navbar-toggler .hamburger span:nth-child(4) {
  top: 16px;
  transition: all .2s;
}
.cid-swfEENVNJh nav.opened .hamburger span:nth-child(1) {
  top: 8px;
  width: 0;
  opacity: 0;
  right: 50%;
  transition: all .2s;
}
.cid-swfEENVNJh nav.opened .hamburger span:nth-child(2) {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  transition: all .25s;
}
.cid-swfEENVNJh nav.opened .hamburger span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  transition: all .25s;
}
.cid-swfEENVNJh nav.opened .hamburger span:nth-child(4) {
  top: 8px;
  width: 0;
  opacity: 0;
  right: 50%;
  transition: all .2s;
}
.cid-swfEENVNJh .collapsed.navbar-expand {
  flex-direction: column;
  -webkit-flex-direction: column;
}
.cid-swfEENVNJh .collapsed .btn {
  display: -webkit-flex;
}
.cid-swfEENVNJh .collapsed .navbar-collapse {
  display: none !important;
  padding-right: 0 !important;
}
.cid-swfEENVNJh .collapsed .navbar-collapse.collapsing,
.cid-swfEENVNJh .collapsed .navbar-collapse.show {
  display: block !important;
}
.cid-swfEENVNJh .collapsed .navbar-collapse.collapsing .navbar-nav,
.cid-swfEENVNJh .collapsed .navbar-collapse.show .navbar-nav {
  display: block;
  text-align: center;
}
.cid-swfEENVNJh .collapsed .navbar-collapse.collapsing .navbar-nav .nav-item,
.cid-swfEENVNJh .collapsed .navbar-collapse.show .navbar-nav .nav-item {
  clear: both;
}
.cid-swfEENVNJh .collapsed .navbar-collapse.collapsing .navbar-nav .nav-item:last-child,
.cid-swfEENVNJh .collapsed .navbar-collapse.show .navbar-nav .nav-item:last-child {
  margin-bottom: 1rem;
}
.cid-swfEENVNJh .collapsed .navbar-collapse.collapsing .navbar-buttons,
.cid-swfEENVNJh .collapsed .navbar-collapse.show .navbar-buttons {
  text-align: center;
}
.cid-swfEENVNJh .collapsed .navbar-collapse.collapsing .navbar-buttons:last-child,
.cid-swfEENVNJh .collapsed .navbar-collapse.show .navbar-buttons:last-child {
  margin-bottom: 1rem;
}
@media (min-width: 1024px) {
  .cid-swfEENVNJh .collapsed:not(.navbar-short) .navbar-collapse {
    max-height: calc(98.5vh - 3.8rem);
  }
}
.cid-swfEENVNJh .collapsed button.navbar-toggler {
  display: block;
}
.cid-swfEENVNJh .collapsed .navbar-brand {
  margin-left: 1rem !important;
}
.cid-swfEENVNJh .collapsed .navbar-toggleable-sm {
  flex-direction: column;
  -webkit-flex-direction: column;
}
.cid-swfEENVNJh .collapsed .dropdown .dropdown-menu {
  width: 100%;
  text-align: center;
  position: relative;
  opacity: 0;
  overflow: hidden;
  display: block;
  height: 0;
  visibility: hidden;
  padding: 0;
  transition-duration: .5s;
  transition-property: opacity,padding,height;
}
.cid-swfEENVNJh .collapsed .dropdown.open > .dropdown-menu {
  position: relative;
  opacity: 1;
  height: auto;
  padding: 1.4rem 0;
  visibility: visible;
}
.cid-swfEENVNJh .collapsed .dropdown .dropdown-submenu {
  left: 0;
  text-align: center;
  width: 100%;
}
.cid-swfEENVNJh .collapsed .dropdown .dropdown-toggle[data-toggle="dropdown-submenu"]::after {
  margin-top: 0;
  position: inherit;
  right: 0;
  top: 50%;
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: .3em;
  vertical-align: middle;
  content: "";
  border-top: .30em solid;
  border-right: .30em solid transparent;
  border-left: .30em solid transparent;
}
@media (max-width: 1023px) {
  .cid-swfEENVNJh .navbar-expand {
    flex-direction: column;
    -webkit-flex-direction: column;
  }
  .cid-swfEENVNJh img {
    height: 1.8rem !important;
  }
  .cid-swfEENVNJh .btn {
    display: -webkit-flex;
  }
  .cid-swfEENVNJh button.navbar-toggler {
    display: block;
  }
  .cid-swfEENVNJh .navbar-brand {
    margin-left: 1rem !important;
  }
  .cid-swfEENVNJh .navbar-toggleable-sm {
    flex-direction: column;
    -webkit-flex-direction: column;
  }
  .cid-swfEENVNJh .navbar-collapse {
    display: none !important;
    padding-right: 0 !important;
  }
  .cid-swfEENVNJh .navbar-collapse.collapsing,
  .cid-swfEENVNJh .navbar-collapse.show {
    display: block !important;
  }
  .cid-swfEENVNJh .navbar-collapse.collapsing .navbar-nav,
  .cid-swfEENVNJh .navbar-collapse.show .navbar-nav {
    display: block;
    text-align: center;
  }
  .cid-swfEENVNJh .navbar-collapse.collapsing .navbar-nav .nav-item,
  .cid-swfEENVNJh .navbar-collapse.show .navbar-nav .nav-item {
    clear: both;
  }
  .cid-swfEENVNJh .navbar-collapse.collapsing .navbar-nav .nav-item:last-child,
  .cid-swfEENVNJh .navbar-collapse.show .navbar-nav .nav-item:last-child {
    margin-bottom: 1rem;
  }
  .cid-swfEENVNJh .navbar-collapse.collapsing .navbar-buttons,
  .cid-swfEENVNJh .navbar-collapse.show .navbar-buttons {
    text-align: center;
  }
  .cid-swfEENVNJh .navbar-collapse.collapsing .navbar-buttons:last-child,
  .cid-swfEENVNJh .navbar-collapse.show .navbar-buttons:last-child {
    margin-bottom: 1rem;
  }
  .cid-swfEENVNJh .dropdown .dropdown-menu {
    width: 100%;
    text-align: center;
    position: relative;
    opacity: 0;
    overflow: hidden;
    display: block;
    height: 0;
    visibility: hidden;
    padding: 0;
    transition-duration: .5s;
    transition-property: opacity,padding,height;
  }
  .cid-swfEENVNJh .dropdown.open > .dropdown-menu {
    position: relative;
    opacity: 1;
    height: auto;
    padding: 1.4rem 0;
    visibility: visible;
  }
  .cid-swfEENVNJh .dropdown .dropdown-submenu {
    left: 0;
    text-align: center;
    width: 100%;
  }
  .cid-swfEENVNJh .dropdown .dropdown-toggle[data-toggle="dropdown-submenu"]::after {
    margin-top: 0;
    position: inherit;
    right: 0;
    top: 50%;
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: .3em;
    vertical-align: middle;
    content: "";
    border-top: .30em solid;
    border-right: .30em solid transparent;
    border-left: .30em solid transparent;
  }
}
@media (min-width: 767px) {
  .cid-swfEENVNJh .menu-logo {
    flex-shrink: 0;
    -webkit-flex-shrink: 0;
  }
}
.cid-swfEENVNJh .navbar-collapse {
  flex-basis: auto;
  -webkit-flex-basis: auto;
}
.cid-swfEENVNJh .nav-link:hover,
.cid-swfEENVNJh .dropdown-item:hover {
  color: white !important;
  text-decoration: underline; 
  text-decoration-color: #FDD964;
  text-underline-offset: 5px;
}
.cid-swfEH7rnkX {
  background-image: url("../../../assets/images/background4.jpg");
}
.cid-swfEH7rnkz {
  background-image: url("../../../assets/images/join-us.png");
}
.cid-swPTzuoObo {
  padding-top: 90px;
  padding-bottom: 90px;
  background-color: #ffffff;
}
.cid-swPTzuoObo h4 {
  font-weight: 500;
  margin-bottom: 0;
  text-align: left;
  padding-top: 2rem;
}
.cid-swPTzuoObo p {
  margin-bottom: 0;
  text-align: left;
  padding-top: 1.5rem;
}
.cid-swPTzuoObo .mbr-section-btn {
  padding-top: 2.5rem;
}
.cid-swPTzuoObo .mbr-section-btn a {
  margin: 0;
}
.cid-swPTzuoObo .mbr-text {
  color: #767676;
}
.cid-swPTzuoObo .card-wrapper {
  height: 100%;
}
@media (max-width: 767px) {
  .cid-swPTzuoObo .card:not(.last-child) {
    padding-bottom: 2rem;
  }
}
.cid-swPTzuoObo H2 {
  text-align: center;
}
.cid-swfEXXThIV {
  padding-top: 90px;
  padding-bottom: 90px;
  background-color: #ffffff;
}
.cid-swfEXXThIV .title {
  margin-bottom: 2rem;
}
.cid-swfEXXThIV .mbr-section-subtitle {
  color: #767676;
}
.cid-swfEXXThIV a:not([href]):not([tabindex]) {
  color: #fff;
  border-radius: 3px;
}
.cid-swfEXXThIV a.btn-white:not([href]):not([tabindex]) {
  color: #333;
}
.cid-swfEXXThIV textarea.form-control {
  min-height: 188px;
}
.cid-swfEZRknqu {
  padding-top: 90px;
  padding-bottom: 90px;
  background-color: #ffffff;
}
.cid-swfEZRknqu .title {
  margin-bottom: 2rem;
}
.cid-swfEZRknqu .mbr-section-subtitle {
  color: #767676;
}
.cid-swfEZRknqu a:not([href]):not([tabindex]) {
  color: #fff;
  border-radius: 3px;
}
.cid-swfEZRknqu a.btn-white:not([href]):not([tabindex]) {
  color: #333;
}
.cid-swfEZRknqu textarea.form-control {
  min-height: 188px;
}
.cid-swfFAP9Cvk {
  padding-top: 30px;
  padding-bottom: 30px;
  background-color: #2e2e2e;
}
